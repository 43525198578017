<template>
  <v-container>
    <v-btn color="primary" @click.stop="dialog = true" plain small>
      Share Player Profile
    </v-btn>

    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title>Share Player Profile</v-card-title>
        <v-card-text>
          <v-text-field
            outlined
            append-outer-icon="mdi-link-variant"
            @click:append-outer="copyLink"
          ></v-text-field>

          <v-btn fab small color="black">
            <v-icon color="white" small>mdi-link-variant</v-icon>
          </v-btn>

          <v-card-title>Share On</v-card-title>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn outlined><v-icon small>mdi-email</v-icon>Email</v-btn>

          <v-btn outlined><v-icon small>mdi-facebook</v-icon>Facebook</v-btn>

          <v-btn outlined><v-icon small>mdi-twitter</v-icon>Twitter</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    copyLink() {},
  },
}
</script>

<style></style>
